<template>
  <div style="background-color: #3477F6;padding-bottom:10px;height:100%;min-height:100vh;">
    <div class="bgi"></div>
    <div class="content-box" v-show="!registerOk">
      <div v-if="phone" style="margin-bottom: 5px;">
        <span style="color:#3477F6;">{{phone}}</span>邀请您加入驿企代理城市代理人计划
      </div>
      <Form @submit="submit">
        <div class="list">
          <div class="list-cell">
            <div class="input-first">+86</div>
            <Field v-model="phoneNumber" placeholder="请输入手机号码"
                   maxlength="11"
                   :rules="[{required:true,message: ''},{ validator: checkNumber, message: '请输入正确的手机号码' },{ validator: checkNumberIsReg, message: '手机号已注册' }]"
            />
          </div>
          <div class="list-cell">
            <Field
                style="border-bottom:none;"
                v-model="code"
                placeholder="请输入验证码"
                maxlength="6"
                :rules="[{required:true,message: ''},{ validator: checkCode, message: '请输入正确的验证码' }]"
            />
            <Button native-type="button" plain style="height:25px;padding:5px 5px;border-radius:3px;width:120px;"
                    @click="getCode">
              {{ s ? `${s}秒` : '获取验证码' }}
            </Button>
          </div>
          <Button block style="background-color: #0079FF;border-radius: 5px;margin-top:20px;" type="info"
                  :disabled="submitDisabled" native-type="submit">加入城市代理人
          </Button>
        </div>
      </Form>
    </div>
    <div class="content-box" v-show="registerOk">
      <div style="color:#FF5E00;font-size: 18px;font-weight: bold;text-align: center;padding:5px 0 25px 0;">领取成功！</div>
      <div>
        请登陆知依昇官网查看，官网地址：
      </div>
      <div class="list-cell" style="border-bottom: none;">
        <Field ref="url" v-model="url" readonly style="font-size:18px;border:1px solid #878585;padding:1px 3px;border-radius: 5px;margin-right:10px;"></Field>
        <Button style="background-color: #0079FF;color:#fff;padding:5px 10px;width:120px;height:30px;border-radius: 5px;"
                @click="copyUrl"
        >复制链接</Button>
      </div>
    </div>
    <div class="content-box">
      <div class="content-title">人工智能商标注册评估</div>
      <p>AI商标注册成功率评估系统，实现自助查询申报，方便代理人为客户查看商标注册成功率</p>
      <div class="content-title">佣金奖励</div>
      <p>代理人自主定价通过我平台为客户办理商标业务后赚取佣金</p>
    </div>
  </div>
</template>

<script>
import {Field, Button, Form, Toast} from 'vant';
import {checkNumber, register, sendSmsMsg,joinCityProxy} from "@/api/api";

export default {
  data() {
    return {
      phoneNumber: '',
      code: '',
      timer: null,
      s: null,
      registerOk:false,
      url:'https://www.guolaoai.com/'
    }
  },
  computed: {
    submitDisabled() {
      return !(this.phoneNumber && this.code)
    },
    phone(){
     return this.$route.query.p || ''
    }
  },
  methods: {
    async getCode() {
      if (this.s) return;
      let check = await this.checkNumberIsReg();
      if (!check) return;
      clearInterval(this.timer);
      this.s = 60;
      this.timer = setInterval(() => {
        if (this.s > 0) {
          this.s--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.s = null;
        }
      }, 1000)
      sendSmsMsg({"userPhone": this.phoneNumber, "userCode": "", "typeNum": 800000001, "params": "", "bigType": 0})
    },
    async checkNumber(val) {
      if (!this.phoneNumber) return false;
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)
    },
    async checkNumberIsReg() {
      if (!this.phoneNumber) return false;
      let res = await checkNumber({
        type: '1',
        userMobile: this.phoneNumber
      })
      if (res.code !== 110) {
        Toast('手机号已注册')
        return false
      }
      return true
    },
    checkCode(val) {
      if (!val || val.length < 6) return false
    },
    async submit(values) {
      console.log('failed', values);
      let previoursUserTel = this.$route.query.p
      let res = await joinCityProxy(
          {"userTel": this.phoneNumber, "verificationCode": this.code, "loginWay": 1, "platformType": "3","previoursUserTel":previoursUserTel}
      )
      if (res.code === 200) {
        this.registerOk = true;
      }
    },
    copyUrl(){
      let url= this.url;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = 'none';
      document.body.removeChild(oInput);
      Toast('复制成功！')
    }
  },
  components: {Field, Button, Form}
}
</script>

<style scoped>
.bgi {
  background-image: url(../../assets/cityAgent.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position-x: center;
  background-position-y: 50px;
  width: 100%;
  padding-top: 70px;
  height: 240px;
}

.content-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  margin: 0 20px 30px 20px;
}

.content-box > p {
  margin-top: 0;
}

.content-title {
  font-size: 18px;
  font-weight: bold;
  color: #3477F6;
  margin-bottom: 10px;
}

.list {

}

.list-cell {
  padding: 8px 0;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  align-items: center;
}

.list-cell .van-cell {
  padding: 0;
}

.van-cell::after {
  border-bottom: none;
}

.input-first {
  background-color: #f5f5f5;
  height: 25px;
  width: 60px;
  line-height: 25px;
  border-radius: 3px;
  font-size: 14px;
  padding: 0 0 0 10px;
  margin-right: 10px;
}
</style>